import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, SubmitButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import closeIcon from "../../../assets/close-icon.svg";
import { useState } from "react";

function ContactForm() {
    const [formData, setFormData] = useState<any>({});
    const submitform = () => {
        console.log("form:", formData);
    };

    const handleChange = (e: any) => {
        let tempFormData = formData;
        tempFormData[e.target.name] = e.target.value;
        setFormData(tempFormData);
    };
    return (
        <div className={`${styles.contactForm} ${responsiveStyles.contactForm}`}>
            <Wrapper>
                <div className={`${styles.contactInner} ${responsiveStyles.contactInner}`}>
                    <div>
                        <h2>Contact Us</h2>
                        <p>We're glad you've decided to get in touch with us regarding your financial claims.</p>
                        <p>We're dedicated to providing top-notch services to help you get the compensation you deserve.</p>
                        <p>
                            To contact us, you can simply fill out the form below with your name, email, and message. We'll get back to you as soon as
                            possible.
                        </p>
                    </div>
                    <div>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <input name="name" type="text" onChange={(e) => handleChange(e)} placeholder="Your Name" />
                            <input name="email" type="email" onChange={(e) => handleChange(e)} placeholder="Your Email" />
                            <textarea name="message" onChange={(e) => handleChange(e)} placeholder="Your Message"></textarea>
                            <SubmitButton text="Submit" btnType="primary" onClick={() => submitform()} />
                        </form>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default ContactForm;
