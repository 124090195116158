import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'

import axios from 'axios'
import { useParams } from 'react-router-dom'
import Notiflix, { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { Wrapper } from '../../reusable'

import { ContactDetails, Person } from '../../formParts'

import stepArrow from '../../../assets/step-arrow2.svg'

import { SaveValueInterface } from '../../../interfaces'
import { fieldValidation, sectionValidation, uploadDataToServer, uploadFileToServer } from '../../../helpers'
import { filesArrayInterface } from '../../../interfaces'
import { LenderDetails } from '../../formParts/lender'
import { UploadDocuments } from '../../formParts/upload'
import { Complete } from '../../formParts/complete'
import { PreviousPerson } from '../../formParts/previousPerson'
import { ContactPreferences } from '../../formParts/contactPreferences'
import { ScrollToTop } from '../../layout'
import { VehicleDetails } from '../../formParts/vehicle'
import { SignatureCCD } from '../../formParts/signatureCCD'
import { SignatureOA } from '../../formParts/signatureOA'
import { useUpdateClaimData } from '../../../claimProvider'
import { SignatureSentinel } from '../../formParts/signatureSentinel'

const steps = [
    {
        text: 'Finance Provider',
        transitionPoint: 0,
        step: 1,
    },
    {
        text: 'Your Details',
        transitionPoint: 3,
        step: 2,
    },
    {
        text: 'E-Sign Documents',
        transitionPoint: 4,
        step: 3,
    },
    {
        text: 'Complete Application',
        transitionPoint: 6,
        step: 4,
    },
]

function ClaimForm() {
    const [claimObject, setClaimObject] = useState<any>({})
    const [groupsComplete, setGroupsComplete] = useState(0)
    const [activeGroup, setActiveGroup] = useState('lender')

    const updateClaim = useUpdateClaimData()

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => e.preventDefault()

    const saveFieldValue = (dataToSave: SaveValueInterface) => {
        console.log(dataToSave)
        let tempClaimObject = claimObject

        if (tempClaimObject) {
            tempClaimObject[dataToSave.field] = dataToSave.value
        }

        setClaimObject(tempClaimObject)
        // console.log("CLAIM OBJECT: ", claimObject);

        const validated = fieldValidation(dataToSave)

        if (validated.success) {
            return validated
        } else {
            return validated
        }
    }

    const activeGroupAssignment = async (group: string) => {
        console.log('next group to display: ', group)
        console.log('CLAIM OBEJECT : ', claimObject)
        setActiveGroup(group)
        if (group == 'signature') {
            const claimData = await uploadDataToServer(claimObject)
            updateClaim(claimData)
        }
        window.scrollTo(0, 0)
        setGroupsComplete(groupsComplete + 1)
    }

    return (
        // <Wrapper>
        <div className={`${styles.formContainer} ${responsiveStyles.formContainer}`}>
            <div className={`${styles.formTitle} ${responsiveStyles.formTitle}`}>
                <p className={styles.tagline}>
                    <span>Make a claim in under 5 minutes!</span>
                </p>
                <h1>Start Your Vehicle Finance Claim</h1>
                <div>
                    <div className={`${styles.progressContainer} ${responsiveStyles.progressContainer}`}>
                        <div className={`${styles.progressBar} ${responsiveStyles.progressBar}`}>
                            {steps.map((e, i) => {
                                return (
                                    <div key={i}>
                                        <div
                                            className={`${styles.progressItem}  ${responsiveStyles.progressItem}  ${responsiveStyles.numberContainer} ${
                                                groupsComplete > e.transitionPoint ? styles.complete : ''
                                            }`}
                                        >
                                            <div className={`${styles.numberContainer} ${responsiveStyles.numberContainer}`}>
                                                <div className={`${styles.number} ${responsiveStyles.number}`}>
                                                    <p>{e.step}</p>
                                                </div>
                                            </div>
                                            <p>{e.text}</p>
                                        </div>
                                        {i + 1 < steps.length && <img src={stepArrow} />}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <form className={`${styles.form}`} onSubmit={(e) => handleSubmit(e)}>
                <div className={styles.formFields}>
                    <LenderDetails saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    <VehicleDetails saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    <Person saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    <ContactDetails saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    {/* <PreviousPerson saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} /> */}
                    {/** THIS WAS CHANGED SO WE CAN ACCOMODATE CCD SIGNATURES.... OA SIGNATURES ARE STILL AVAILABLE THROUGH ORIGINAL SIGNATURE COMPONENT */}
                    {/* <SignatureCCD saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />{" "} */}
                    <SignatureSentinel saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    {/* <ContactPreferences saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} /> */}
                    <UploadDocuments saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    <Complete isActive={activeGroup} saveValueFunct={saveFieldValue} assignGroup={activeGroupAssignment} />
                </div>
            </form>
        </div>
        // </Wrapper>
    )
}

export default ClaimForm
