import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { OriginalHeader } from "./headerTypes";

function Header() {
    const [headerType, setHeaderType] = useState<String>("");
    const { token } = useParams();

    useEffect(() => {
        assignHeaderType();
    }, []);

    function assignHeaderType() {
        const pcs = window.location.href.split("/");
        setHeaderType(pcs[pcs.length - 2]);
    }

    function selectHeader() {
        switch (headerType) {
            case "claim":
                return <OriginalHeader />;
                break;
            default:
                return <OriginalHeader />;
                break;
        }
    }
    return selectHeader();
}

export default Header;
