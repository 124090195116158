import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ScrollToTop } from './components/layout/scrollTop/scrollToTop'

import Main from './containers/main'
import MakeClaim from './containers/makeClaim'
import Home from './containers/home'
import Contact from './containers/contact'
import UploadDocument from './containers/uploadId'
import SignDocuments from './containers/signDocuments'
import DocumentView from './containers/document'

export default function AppRouter() {
    // TEST
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route path="/" element={<Home />} />
                    <Route path="claim/start" element={<MakeClaim />} />
                    {/* <Route path="claim/sign-oa-loa/:token" element={<SignDocuments docsToSign="oa-loa" />} />
                    <Route path="claim/sign-ccd-loa/:token" element={<SignDocuments docsToSign="ccd-loa" />} />
                    <Route path="claim/sign-hyperian-cfa/:token" element={<SignDocuments docsToSign="hyperian-cfa" />} /> */}
                    <Route path="claim/sign-sentinel-cfa/:token" element={<SignDocuments docsToSign="sentinel-cfa" />} />
                    <Route path="claim/upload-id/:token" element={<UploadDocument />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="documents/:fileName" element={<DocumentView />} />
                </Route>
            </Routes>
        </Router>
    )
}
