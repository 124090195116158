import React, { useEffect, useState } from 'react'
// import styles from "./styles.module.scss";
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard, SelectButton, CtaButton } from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { lenderData } from '../../../data/lenders'
import { sectionValidation } from '../../../helpers'
import { dvlaCheckService } from '../../../services/dvla-check'
import { Loading } from 'notiflix'

export const VehicleDetails = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})
    const [carMake, setCarMake] = useState('')
    const [carFuelType, setCarFuelType] = useState('')
    const [carColour, setCarColour] = useState('')
    const [carYear, setCarYear] = useState('')

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave)
        let tempStepData = stepData
        tempStepData[dataToSave.field] = dataToSave.value
        setStepData(tempStepData)
        return saved
    }

    const animateBeforeAssign = (assignment: string) => {
        if (!sectionValidation(stepData, 'vehicle').success) return
        setTimeout(() => {
            assignGroup(assignment)
        }, 400)
    }

    const populateCarDetails = async () => {
        Loading.standard('Checking Vehicle...')
        const carData = await dvlaCheckService(stepData.registration)
        if (!carData.success) {
            Loading.remove()
            Report.failure('Invalid registration.', 'Please enter a valid registration.', 'OK')
            return
        }

        const savedMake = saveValueFunct({ field: 'make', value: carData.make })
        const savedColour = saveValueFunct({ field: 'colour', value: carData.colour })
        const savedFuel = saveValueFunct({ field: 'carFuelType', value: carData.fuelType })
        const savedYear = saveValueFunct({ field: 'carYear', value: String(carData.year) })
        const savedRegistrationYear = saveValueFunct({ field: 'registrationYear', value: String(carData.registrationYear) })

        let tempStepData = stepData
        tempStepData.make = carData.make
        tempStepData.colour = carData.colour
        tempStepData.carFuelType = carData.fuelType
        tempStepData.carYear = String(carData.year)
        tempStepData.registrationYear = String(carData.registrationYear)

        setStepData(tempStepData)

        setCarYear(carData.year)
        setCarColour(carData.colour)
        setCarFuelType(carData.fuelType)
        setCarMake(carData.make)
        Loading.remove()
    }

    const getCurrentStep = () => {
        return isActive == 'vehicle' ? (
            <FieldGroup title="Vehicle Details">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    {/* <FieldContainer> */}
                    <Field extraStyle={{ padding: '0' }} fieldLabel="Vehicle Registration" labelPosition="left">
                        <InputFieldText fieldName="registration" saveValue={preSaveValueFunct} />
                    </Field>{' '}
                    <SubmitButton text="Check" onClick={() => populateCarDetails()} btnType="primary" />
                    {/* </FieldContainer> */}
                    {carMake !== '' ? (
                        <>
                            <br />
                            <FieldContainer>
                                <Field fieldLabel="Car Make" labelPosition="left">
                                    <InputFieldText fieldName="make" saveValue={preSaveValueFunct} populateValue={carMake} />
                                </Field>
                                <Field fieldLabel="Car Colour" labelPosition="left">
                                    <InputFieldText fieldName="colour" saveValue={preSaveValueFunct} populateValue={carColour} />
                                </Field>
                            </FieldContainer>

                            <FieldContainer>
                                <Field fieldLabel="Car Year of Manufacture" labelPosition="left">
                                    <InputFieldText fieldName="carYear" saveValue={preSaveValueFunct} populateValue={carYear} />
                                </Field>
                                <Field fieldLabel="Car Fuel Type" labelPosition="left">
                                    <InputFieldText fieldName="carFuelType" saveValue={preSaveValueFunct} populateValue={carFuelType} />
                                </Field>
                            </FieldContainer>
                            <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign('person')} />
                        </>
                    ) : (
                        <></>
                    )}
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
