import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";

import axios from "axios";
import { useParams } from "react-router-dom";
import Notiflix, { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../../reusable";

import stepArrow from "../../../assets/step-arrow2.svg";

import { SaveValueInterface } from "../../../interfaces";
import { fieldValidation, uploadFileToServer } from "../../../helpers";
import { filesArrayInterface } from "../../../interfaces";
import { UploadDocuments, Complete } from "../../formParts";

const steps = [
    {
        text: "Upload ID",
        transitionPoint: 0,
        step: 1,
    },
    {
        text: "Complete Application",
        transitionPoint: 1,
        step: 2,
    },
];

function UploadForm() {
    const [claimObject, setClaimObject] = useState<any>({});
    const [groupsComplete, setGroupsComplete] = useState(0);
    const [activeGroup, setActiveGroup] = useState("upload");

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const saveFieldValue = (dataToSave: SaveValueInterface) => {
        return { success: true, error: "" };
    };

    const activeGroupAssignment = async (group: string) => {
        console.log("next group to display: ", group);
        console.log("CLAIM OBEJECT : ", claimObject);
        setActiveGroup(group);
        window.scrollTo(0, 0);
        setGroupsComplete(groupsComplete + 2);
    };

    return (
        // <Wrapper>
        <div className={`${styles.formContainer} ${responsiveStyles.formContainer}`}>
            <div className={`${styles.formTitle} ${responsiveStyles.formTitle}`}>
                <p className={styles.tagline}>
                    <span>Upload your documents!</span>
                </p>
                <h1>Finish Your Vehicle Finance Claim</h1>
                <div>
                    <div className={`${styles.progressContainer} ${responsiveStyles.progressContainer}`}>
                        <div className={`${steps.length < 4 ? styles.centerFlex : ""}  ${styles.progressBar} ${responsiveStyles.progressBar}`}>
                            {steps.map((e, i) => {
                                return (
                                    <>
                                        <div
                                            className={`${styles.progressItem}  ${responsiveStyles.progressItem}  ${
                                                responsiveStyles.numberContainer
                                            } ${groupsComplete > e.transitionPoint ? styles.complete : ""}`}
                                        >
                                            <div className={`${styles.numberContainer} ${responsiveStyles.numberContainer}`}>
                                                <div className={`${styles.number} ${responsiveStyles.number}`}>
                                                    <p>{e.step}</p>
                                                </div>
                                            </div>
                                            <p>{e.text}</p>
                                        </div>
                                        {i + 1 < steps.length && <img src={stepArrow} />}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <form className={`${styles.form}`} onSubmit={(e) => handleSubmit(e)}>
                <div className={styles.formFields}>
                    <UploadDocuments saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    <Complete isActive={activeGroup} saveValueFunct={saveFieldValue} assignGroup={activeGroupAssignment} />
                </div>
            </form>
        </div>
        // </Wrapper>
    );
}

export default UploadForm;
