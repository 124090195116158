
export const requiredPersonFields = [
    'title',
    'firstname',
    'lastname',
    'dob',
]
export const requiredContactFields = [
    'address_line_one',
    'city',
    'postcode',
    'telephone',
    'email',
]
export const requiredFinanceFields = [
    'lender',
    'financeAmount'
]
export const requiredSignatureFields = [
    'acceptTerms',
]

export const requiredFilesFields = [
    // 'financeAgreement',
    'poid'
]
export const requiredVehicleFields = [
    'make',
    'colour',
    'carYear',
    'carFuelType',
    'registrationYear'
]