import styles from './styles.module.scss'
import { ButtonContainer, CtaButton, SubmitButton, Wrapper } from '../../reusable'
import responsiveStyles from './responsiveStyles.module.scss'

import heroimg from '../../../assets/car-hero.png'
import tagTick from '../../../assets/tag-tick.svg'
import lines from '../../../assets/lines.svg'
import ClaimForm from '../../claimForm/main'
import { IsMobile } from '../../layout'
import { Link } from 'react-router-dom'
function Hero() {
    const scrollToSection = (name: string) => {
        let section = document.getElementsByClassName(name)
        section[0].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }

    return (
        <div className={`${styles.hero} ${responsiveStyles.hero}  `}>
            <Wrapper>
                <div className={`${styles.heroContent} ${responsiveStyles.heroContent}`}>
                    <h1>
                        Have You Been Mis-Sold?
                        <br />
                        <span>We Can Help!</span>
                    </h1>
                    <div className={styles.lines}>
                        <img src={lines} />
                    </div>
                    <img src={heroimg} />
                    <div className={`${styles.tagLine} ${responsiveStyles.tagLine}`}>
                        <div>
                            <img src={tagTick} />
                            <p>Free no obligation check!</p>
                        </div>
                        <div>
                            <img src={tagTick} />
                            <p>Easy and hassle free!</p>
                        </div>
                        <div>
                            <img src={tagTick} />
                            <p>You could be owed £1000s</p>
                        </div>
                    </div>
                    {IsMobile() ? <CtaButton text="Make A Claim" /> : <></>}
                    <ul>
                        <li onClick={() => scrollToSection('ourSolution')}>3 Easy Steps</li>
                        <li onClick={() => scrollToSection('clients')}>Client Reviews</li>
                        <li onClick={() => scrollToSection('knowPCP')}>PCP Agreements</li>
                        <li onClick={() => scrollToSection('whatIsPcp')}>What is PCP?</li>
                        <li onClick={() => scrollToSection('benefits')}>Our Benefits</li>
                        <li onClick={() => scrollToSection('claim')}>Claim Now!</li>
                    </ul>
                </div>
            </Wrapper>
        </div>
    )
}

export default Hero
