import s from "./styles.module.scss";
import { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface inputFieldDateProps {
    fieldName: string;
    saveValue: (obj: { field: string; value: string }) => { success: boolean; error: string };
}

export const InputFieldDate = (props: inputFieldDateProps) => {
    const { saveValue, fieldName } = props;
    const [valueToSave, setValueToSave] = useState(new Date());

    useEffect(() => {
        let tempVal = valueToSave;
        tempVal.setFullYear(tempVal.getFullYear() - 18);
        setValueToSave(tempVal);
    }, []);

    const [error, setError] = useState("");
    const onChangeFunc = (e: Date | null) => {
        if (e != null) {
            setError("");
            // console.log("INPUT DATE FIELD CHANGED...", newValue);
            setValueToSave(e);
            const saveResult = saveValue({ field: fieldName, value: String(new Date(e).toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'})) });
            if (!saveResult.success) {
                setError(saveResult.error);
            }
        }
    };

    var eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    var ninetyYearsAgo = new Date();
    ninetyYearsAgo.setFullYear(ninetyYearsAgo.getFullYear() - 90);

    return (
        <>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                dropdownMode="select"
                showMonthDropdown
                // adjustDateOnChange
                minDate={ninetyYearsAgo}
                maxDate={eighteenYearsAgo}
                showYearDropdown
                selected={valueToSave}
                onChange={(date) => onChangeFunc(date)}
                className={s.inputDateStyle}
                placeholderText="Select Date"
            />
            <span className={`${s.notice} {${error.length < 1 ? "error" : ""}`}>{error}</span>
        </>
    );
};
