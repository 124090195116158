import { Report } from 'notiflix/build/notiflix-report-aio'
import { Loading } from 'notiflix'

/**
 * "Couldn't retrieve company information"
 * 'Lead already signed'
 */
export const apiErrors = (serverError: string) => {
    if (serverError === 'Token not found' || serverError === 'no token') {
        Report.failure(
            'Unable to locate account.',
            "We were unable to locate your case, contact support@sentinellegal.co.uk' for more information.",
            'OK',
            () => (window.location.href = 'https://google.co.uk')
        )
    } else {
        console.log('ERROR - fail...')

        Report.failure('Internal Error', "We've encountered an internal error, please try again later.", 'OK', () => {
            // window.location.href = "https://google.co.uk";
        })
    }

    return true
}
