import ContactForm from "../components/pageComponents/ContactForm";
import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";

function Contact() {
    return (
        <div className={`${styles.pageContainer}`}>
            <ContactForm />
        </div>
    );
}

export default Contact;
