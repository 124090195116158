import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard, InputFile } from '../../reusable'
import { filesArrayInterface } from '../../../interfaces/file'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { lenderData } from '../../../data/lenders'
import { sectionValidation, uploadFileToServer } from '../../../helpers'
import { useClaimData } from '../../../claimProvider'
import { useParams } from 'react-router-dom'
import { IsMobile } from '../../layout'

export const UploadDocuments = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const { token } = useParams()
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})
    const [uploadFiles, setUploadFiles] = useState<boolean>(false)
    const [filesArray, setFilesArray] = useState<Array<filesArrayInterface>>([])
    const claim = useClaimData()

    // const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
    //     const saved = saveValueFunct(dataToSave);
    //     let tempStepData = stepData;
    //     tempStepData[dataToSave.field] = dataToSave.value;
    //     setStepData(tempStepData);
    //     return saved;
    // };

    const animateBeforeAssign = async (assignment: string) => {
        console.log(stepData)

        if (!sectionValidation(stepData, 'files').success) return
        const usableToken = token ? token : claim?.idUploadData?.token

        if (usableToken) {
            const idUploaded = await uploadFileToServer(usableToken, filesArray)
            if (idUploaded.success) {
                setTimeout(() => {
                    assignGroup(assignment)
                }, 400)
            }
        }
    }

    const attachFile = (fileData: any) => {
        console.log('file data: ', fileData)
        let tempStepData = stepData
        tempStepData[fileData.fileInputName] = 'yes'
        setStepData(tempStepData)
        let filteredFiles = filesArray.filter((searchedFile) => searchedFile.fileInputName !== fileData.fileInputName)
        filteredFiles.push(fileData)
        setFilesArray(filteredFiles)
    }

    const getCurrentStep = () => {
        return isActive == 'upload' ? (
            <FieldGroup title="Vehicle Finance Information">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    {window.innerWidth <= 992 ? (
                        <>
                            <p className={styles.question}>Please upload a picture of your driving licence</p>
                            <FieldContainer>
                                <Field extraStyle={{ padding: '0', margin: '0 auto' }} fieldLabel="" labelPosition="left">
                                    <InputFile text={``} niceName={``} fileInputName={`poid`} attachFileToForm={attachFile} />
                                </Field>
                            </FieldContainer>
                            <br />
                            <p className={styles.normal}>Accepted file formats include: JPEG, PNG, HEIC, HEIF, WEBP, AVIF</p>
                            <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign('complete')} />
                            <br />
                            <p className={styles.normal}>An acceptable ID picture must meet the requirements below:</p>
                            <div>
                                <ul className={styles.normalList}>
                                    <li>All four corners of the ID card must be in the picture.</li>
                                    <li>All text on the licence must be visible and readable.</li>
                                    <li>The ID card must not have obstructing glare, reflections or blurring.</li>
                                    <li>The ID card must display in the correct orientation.</li>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className={styles.question}>Please upload a picture of your driving licence</p>
                            <p className={styles.normal}>An acceptable ID picture must meet the requirements below:</p>
                            <FieldContainer>
                                <Field extraStyle={{ padding: '0', margin: '0 auto' }} fieldLabel="" labelPosition="left">
                                    <InputFile text={``} niceName={``} fileInputName={`poid`} attachFileToForm={attachFile} />
                                </Field>
                                <div>
                                    <ul className={styles.normalList}>
                                        <li>All four corners of the ID card must be in the picture.</li>
                                        <li>All text on the licence must be visible and readable.</li>
                                        <li>The ID card must not have obstructing glare, reflections or blurring.</li>
                                        <li>The ID card must display in the correct orientation.</li>
                                    </ul>
                                </div>
                            </FieldContainer>
                            <br />
                            <p className={styles.normal}>Accepted file formats include: JPEG, PNG, HEIC, HEIF, WEBP, AVIF</p>
                            <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign('complete')} />
                        </>
                    )}
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
