import s from "./styles.module.scss";
import { Link } from "react-router-dom";

interface SubmitButtonProps {
    text: string;
    btnType: string;
    onClick: (event: React.MouseEvent) => void;
    isSelected?: boolean;
}

export const SubmitButton = (props: SubmitButtonProps) => {
    const { text, onClick, btnType } = props;
    return (
        <button onClick={onClick} className={`${s.cta} ${btnType === "primary" ? s.primary : btnType === "secondary" ? s.secondary : s.third}`}>
            <span>{text}</span>
            {/* <img src={btnType == "secondary" ? goldArrow : blackArrow} /> */}
        </button>
    );
};

export const OptionButton = (props: SubmitButtonProps) => {
    const { text, onClick, isSelected } = props;
    return (
        <button onClick={onClick} className={`${s.selectOptionButton} ${isSelected ? s.selectedOption : s.unselectedOption}`}>
            <span>{text}</span>
            {/* <img src={btnType == "secondary" ? goldArrow : blackArrow} /> */}
        </button>
    );
};

export const SelectButton = (props: SubmitButtonProps) => {
    const { text, onClick, isSelected } = props;
    return (
        <button onClick={onClick} className={`${s.selectButton} ${isSelected ? s.selected : s.unselected}`}>
            <span>{text}</span>
            {/* <img src={btnType == "secondary" ? goldArrow : blackArrow} /> */}
        </button>
    );
};

export const CtaButton = (props: { text: string }) => {
    const { text } = props;
    const onClick = () => {
        console.log("scroll to top....");
        // window.scrollTo({ top: 0, behavior: "smooth" });
        window.location.href = "/claim/start";
    };
    return (
        <button onClick={onClick} className={s.ctaButton}>
            <span>{text}</span>
            {/* <img src={btnType == "secondary" ? goldArrow : blackArrow} /> */}
        </button>
    );
};
