import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import img1 from "../../../assets/advice.svg";
import img2 from "../../../assets/nowinnofee.svg";
import img3 from "../../../assets/free-assessment.svg";
import img4 from "../../../assets/excellent-track-record.svg";

function LawPartners() {
    return (
        <div className={`${styles.lawPartners} ${responsiveStyles.lawPartners}`}>
            <Wrapper>
                <div className={`${styles.lawPartnersInner} ${responsiveStyles.lawPartnersInner}`}>
                    <h2>Our Law Partners</h2>
                    <p>A team of legal advisers are here to help you!</p>

                    <div className={`${styles.fourPoints} ${responsiveStyles.fourPoints}`}>
                        <div>
                            <img src={img1} />
                        </div>
                        <div>
                            <img src={img2} />
                        </div>
                        <div>
                            <img src={img3} />
                        </div>
                        <div>
                            <img src={img4} />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default LawPartners;
