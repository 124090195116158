import React, { useEffect, useState } from 'react'
// import styles from "./styles.module.scss";
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard } from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { sectionValidation } from '../../../helpers'

export const ContactDetails = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})

    const [AddressText, setAddressText] = useState('')
    const [AddressOne, setAddressOne] = useState('')
    const [AddressTwo, setAddressTwo] = useState('')
    const [AddressThree, setAddressThree] = useState('')
    const [AddressCity, setAddressCity] = useState('')
    const [AddressPostcode, setAddressPostcode] = useState('')

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        if (dataToSave.field == 'searchAddress') {
            saveAddress(dataToSave.value)
            return { success: true, error: '' }
        }

        const saved = saveValueFunct(dataToSave)
        let tempStepData = stepData
        tempStepData[dataToSave.field] = dataToSave.value

        setStepData(tempStepData)
        return saved
    }

    const saveAddress = (addressString: string) => {
        setAddressText(addressString)
        const addressArr = addressString.split(', ')

        console.log('ADDRESS LENGTH: ', addressArr.length)

        let addressLineOne = addressArr[0]
        let addressLineTwo = addressArr.length > 3 ? addressArr[1] : ''
        let addressLineThree = addressArr.length > 4 ? addressArr[addressArr.length - 3] : ''
        let city = addressArr[addressArr.length - 2]
        let postcode = addressArr[addressArr.length - 1]

        let tempStepData = stepData

        setAddressOne(addressLineOne)
        const lineOneSaved = saveValueFunct({ field: 'address_line_one', value: addressLineOne })
        tempStepData.address_line_one = addressLineOne

        setAddressTwo(addressLineTwo)
        const lineTwoSaved = saveValueFunct({ field: 'address_line_two', value: addressLineTwo })
        tempStepData.address_line_two = addressLineTwo

        setAddressThree(addressLineThree)
        const lineThreeSaved = saveValueFunct({ field: 'address_line_three', value: addressLineThree })
        tempStepData.address_line_three = addressLineThree

        setAddressCity(city)
        const citySaved = saveValueFunct({ field: 'city', value: city })
        tempStepData.city = city

        setAddressPostcode(postcode)
        const postcodeSaved = saveValueFunct({ field: 'postcode', value: postcode })
        tempStepData.postcode = postcode

        setStepData(tempStepData)
    }

    const animateBeforeAssign = (assignment: string) => {
        if (!sectionValidation(stepData, 'contact').success) return
        setTimeout(() => {
            assignGroup(assignment)
        }, 400)
    }

    const getCurrentStep = () => {
        return isActive == 'contact' ? (
            <FieldGroup title="Contact Information">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <Field extraStyle={{ padding: '0' }} fieldLabel="Telephone" labelPosition="left">
                        <InputFieldText fieldName="telephone" saveValue={preSaveValueFunct} fieldType={'tel'} />
                    </Field>
                    <Field extraStyle={{ padding: '0' }} fieldLabel="Email" labelPosition="left">
                        <InputFieldText fieldName="email" saveValue={preSaveValueFunct} fieldType={'email'} />
                    </Field>
                    <Field extraStyle={{ padding: '0' }} fieldLabel="Search Address" labelPosition="left">
                        <InputFieldAddressLookup fieldName={`searchAddress`} saveValue={preSaveValueFunct} />
                    </Field>
                    {AddressText.length > 0 && (
                        <>
                            <Field extraStyle={{ padding: '0' }} fieldLabel="Address Line 1" labelPosition="left">
                                <InputFieldText fieldName="address_line_one" saveValue={preSaveValueFunct} populateValue={AddressOne} />
                            </Field>
                            <Field extraStyle={{ padding: '0' }} fieldLabel="Address Line 2" labelPosition="left">
                                <InputFieldText fieldName="address_line_two" saveValue={preSaveValueFunct} populateValue={AddressTwo} />
                            </Field>
                            <Field extraStyle={{ padding: '0' }} fieldLabel="Address Line 3" labelPosition="left">
                                <InputFieldText fieldName="addres_line_three" saveValue={preSaveValueFunct} populateValue={AddressThree} />
                            </Field>
                            <Field extraStyle={{ padding: '0' }} fieldLabel="City" labelPosition="left">
                                <InputFieldText fieldName="city" saveValue={preSaveValueFunct} populateValue={AddressCity} />
                            </Field>
                            <Field extraStyle={{ padding: '0' }} fieldLabel="Postcode" labelPosition="left">
                                <InputFieldText fieldName="postcode" saveValue={preSaveValueFunct} populateValue={AddressPostcode} />
                            </Field>
                        </>
                    )}
                    <p>By clicking the button below, you confirm you give your permission for Sentinel Legal Ltd to contact you by telephone, e-mail or post regarding your claim.</p>
                    <SubmitButton text="Submit" btnType="primary" onClick={() => animateBeforeAssign('signature')} />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
