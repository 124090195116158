import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'

import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { validFile } from '../../../helpers/fileValidation'

import pdf from '../../../assets/pdf.png'
import addfile from '../../../assets/add-file.png'
interface fileInputInterface {
    fileInputName: string
    attachFileToForm: any
    niceName: string
    text: string
}

export const InputFile = (fileData: fileInputInterface) => {
    const { text, fileInputName, attachFileToForm } = fileData
    const [fileObject, setFileObject] = useState(addfile)
    const [fileObjectToUpload, setFileObjectToUpload] = useState<File>()
    const [filename, setFilename] = useState('')

    const fileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        Loading.standard('Checking file...')
        const files = e.target?.files != null ? e.target.files : []
        if (!validFile(files, setFileObject, addfile)) return

        const file = files[0]
        let imagePreview = file.type === 'application/pdf' ? pdf : URL.createObjectURL(file)
        // if (file.type == "image/heic") {
        //     imagePreview = addfile;
        // }
        setFileObject(imagePreview)

        setFileObjectToUpload(file)
        setFilename(file.name)
        const formAttachment = { fileInputName, filename: file.name, file, fileType: file.type }
        attachFileToForm(formAttachment)
        Loading.remove()
    }

    return (
        <div className={styles.fileInputContainer}>
            <p>{text}</p>
            <div>
                <input type="file" name={fileInputName} id={fileInputName} accept=".jpg, .jpeg, .png, .heic, .heif, .webp, .avif" onChange={fileChanged} />
                <label className={styles.fileLabel} htmlFor={fileInputName}>
                    <img src={fileObject} /> {!filename.length ? <span>Click above to select a file.</span> : <span>{filename}</span>}
                </label>
            </div>
        </div>
    )
}
