import React, { useEffect, useState } from 'react'
// import styles from "./styles.module.scss";
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard } from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { lenderData } from '../../../data/lenders'
import { SignaturePad } from '../../reusable/sigPad'
import { sectionValidation } from '../../../helpers'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'
import { useClaimData } from '../../../claimProvider'
import Previewer from '../../reusable/previewer'

export const SignatureOA = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})
    const claim = useClaimData()

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave)
        let tempStepData = stepData
        tempStepData[dataToSave.field] = dataToSave.value
        setStepData(tempStepData)
        return saved
    }

    const animateBeforeAssign = (assignment: string) => {
        console.log(stepData)

        if (!sectionValidation(stepData, 'signature').success) return
        setTimeout(() => {
            assignGroup(assignment)
        }, 400)
    }

    const getCurrentStep = () => {
        return isActive == 'signature' && claim?.sigLinkData ? (
            <FieldGroup title="Sign Your Letter of Authority">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <Checkbox
                        fieldName={'acceptTerms'}
                        label={
                            'I agree that I have read, understand and accept the Olton Alexander Ltd terms of business and that the check is free. I also agree to instruct Olton Alexander to send a data subject access request (DSAR) to find out if my PCP or HP agreements were mis-sold.'
                        }
                        saveValue={preSaveValueFunct}
                    />
                    <br />
                    <p>In order for us to progress your claim please sign in the box below agreeing to our terms of business.</p>
                    <SignaturePad
                        extraPayload={{ browser: browserName, device: osName, type: 'oaSign', token: claim.sigLinkData.token }}
                        additionalAction={() => animateBeforeAssign(claim?.isResuming ? 'complete' : 'contactPreferences')}
                        disclaimer={
                            'We will carry out a soft credit check to validate your case as well as verify your identity and prevent money laundering. This will not impact your credit rating in any way and only data relevant to Vehicle finance will be gathered from your file'
                        }
                    />
                    <br />
                    {claim?.loaUrl ? <Previewer url={claim.loaUrl} documentString={'LOA'} /> : <></>}
                    {claim?.termsUrl ? <Previewer url={claim.termsUrl} documentString={'Terms'} /> : <></>}
                    {claim?.fcaUrl ? <Previewer url={claim.fcaUrl} documentString={'FCA'} /> : <></>}
                    {claim?.dsarUrl ? <Previewer url={claim.dsarUrl} documentString={'DSAR'} /> : <></>}
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
