import styles from './styles.module.scss'
import { ButtonContainer, CtaButton, Wrapper } from '../../reusable'
import responsiveStyles from './responsiveStyles.module.scss'

import claimwoman from '../../../assets/claim-woman.png'

function HaveAClaim() {
    return (
        <div className={`${styles.haveClaim} ${responsiveStyles.haveClaim}`}>
            <Wrapper>
                <div className={`${styles.haveClaimInner} ${responsiveStyles.haveClaimInner}`}>
                    <div>
                        <h2>Do I have a claim?</h2>
                        <p>If you have taken out a PCP policy within the last 10 years, it’s likely you may have a case for a mis-sold PCP agreement.</p>
                        <CtaButton text="Check Now" />
                    </div>
                    <img src={claimwoman} />
                </div>
            </Wrapper>
        </div>
    )
}

export default HaveAClaim
