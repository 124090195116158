import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import whatispcp from "../../../assets/whatispcp.png";
import thumb from "../../../assets/thumbup.svg";
import shield from "../../../assets/shieldtick.svg";
import money from "../../../assets/moneybag.svg";

interface testimonialFormat {
    text: string;
    title: string;
    amount: string;
    author: string;
    location: string;
    img: string;
    positionTop: boolean;
}

function Testimonial(props: testimonialFormat) {
    const { text, title, amount, author, location, img, positionTop } = props;

    return (
        <div
            className={`${styles.testimonial} ${responsiveStyles.testimonial} ${
                positionTop ? `${responsiveStyles.tp} ${styles.tp}` : `${responsiveStyles.bp} ${styles.bp}`
            } `}
        >
            {positionTop ? (
                <div className={`${styles.imgContainer} ${responsiveStyles.imgContainer}`}>
                    <img src={img} />
                </div>
            ) : (
                <></>
            )}
            <div className={`${styles.testimonialContent} ${responsiveStyles.testimonialContent}`}>
                <p className={`${styles.title} ${responsiveStyles.title}`}>
                    {amount}
                    <span>{title}</span>
                </p>
                <p>{text}</p>
                <p className={`${styles.author} ${responsiveStyles.author}`}>
                    {author}
                    <span>{location}</span>
                </p>
            </div>
            {!positionTop ? (
                <div className={`${styles.imgContainer} ${responsiveStyles.imgContainer}`}>
                    <img src={img} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default Testimonial;
