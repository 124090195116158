import styles from './styles.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import logo from '../../../../../assets/carclaimslogo.png'
import slogo from '../../../../../assets/sentinel-logo.png'
import { Wrapper } from '../../../../reusable'
import { Link } from 'react-router-dom'

export function OriginalFooter() {
    return (
        <div className={`${styles.footer} ${responsiveStyles.footer}`}>
            <Wrapper>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    <div>
                        <nav>
                            <ul>
                                <li>
                                    <Link to={'/'}>Home </Link>
                                </li>
                                <li>
                                    <Link to={'/contact'}>Contact </Link>
                                </li>
                                <li>
                                    <Link to={'/claim/start'}>Check Now</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <p>
                            www.missold-finance.co.uk is a website and trading name operated by Sentinel Legal Ltd. Sentinel legal Limited is Registered in England and Wales. Registered
                            office Harley house, 29 Cambray Place, Cheltenham, England, GL50 1JN. Authorised and regulated by the Solicitors Regulation Authority.
                        </p>
                        <ul>
                            <li>SRA number 811792</li>
                            <li>Company number 12826774</li>
                            <li>VAT registration number 423 9685 69</li>
                        </ul>
                        <br />
                        <h5>Press Releases</h5>
                        <ul className={styles.links}>
                            <li>
                                <a href="https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commission-models">
                                    https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commission-models
                                </a>
                            </li>
                            <li>
                                <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf">
                                    https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
                                </a>
                            </li>
                        </ul>
                        <hr />
                        <div className={styles.footerbottom}>
                            <div className={styles.logoContainer}>
                                <p>© {new Date().getFullYear()} Sentinel Legal Ltd.</p>
                                <img src={slogo} />
                            </div>
                            <div className={`${styles.terms} ${responsiveStyles.terms}`}>
                                <p>
                                    <a href="https://sentinellegal.co.uk/privacy-policy" target="__blank">
                                        Terms and Conditions
                                    </a>
                                </p>
                                <p>
                                    <a href="https://sentinellegal.co.uk/privacy-policy" target="__blank">
                                        Privacy Policy
                                    </a>
                                </p>
                                {/* <p>
                                    <a href="../../../../documents/nowinnofee.pdf" target="__blank">
                                        No Win, No Fee Policy
                                    </a>
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}
