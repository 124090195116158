import styles from './styles.module.scss'
import { ButtonContainer, CtaButton, Wrapper } from '../../reusable'
import responsiveStyles from './responsiveStyles.module.scss'

import moneybag from '../../../assets/big-money-bag.svg'

function DidYouFinance() {
    return (
        <div className={`claim ${styles.didYouFinance} ${responsiveStyles.didYouFinance}`}>
            <Wrapper>
                <div className={`${styles.didYouFinanceInner} ${responsiveStyles.didYouFinanceInner}`}>
                    <div>
                        <h2>Did You Finance A Car In The Past 10 Years?</h2>
                        <CtaButton text="Check Now" />
                    </div>
                    <img src={moneybag} />
                </div>
            </Wrapper>
        </div>
    )
}

export default DidYouFinance
