import { IObjectKey } from "../interfaces";
import axios from "axios";
export async function dvlaCheckService(registration: string) {
    try {
        let axiosData = {
            method: "POST",
            url: process.env.REACT_APP_DVLA_ENDPOINT,
            timeout: 180000,
            data: { registration: registration.replace(" ", "").trim() },
        };

        console.log("AXIOS DATA: ", axiosData);
        const response = await axios(axiosData);
        console.log("DVLA RESPONSE: ", response.data);
        return response.data;
    } catch (error: any) {
        console.log("DVLA ERROR: ", error);
        if (error?.response && error.response.data) {
            console.log("MY ERROR......");
            return error.response.data;
        }
        return { success: false };
    }
    /**
     *      EXAMPLE RESPONSE....
        {
            "registrationNumber": "YC09UTE",
            "taxStatus": "Taxed",
            "taxDueDate": "2024-08-01",
            "motStatus": "Valid",
            "make": "BMW",
            "yearOfManufacture": 2009,
            "engineCapacity": 1995,
            "co2Emissions": 151,
            "fuelType": "PETROL",
            "markedForExport": false,
            "colour": "BLACK",
            "typeApproval": "M1",
            "dateOfLastV5CIssued": "2023-06-15",
            "motExpiryDate": "2024-02-13",
            "wheelplan": "2 AXLE RIGID BODY",
            "monthOfFirstRegistration": "2009-07"
        }
     */
}
