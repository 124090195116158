import React, { useEffect, useState } from 'react'
// import styles from "./styles.module.scss";
import { Report } from 'notiflix/build/notiflix-report-aio'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard, InputFieldDate } from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { sectionValidation } from '../../../helpers'
import { Data8Service } from '../../../services'
// import
export const Person = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [stepData, setStepData] = useState<any>({})

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave)
        let tempStepData = stepData
        tempStepData[dataToSave.field] = dataToSave.value
        setStepData(tempStepData)
        return saved
    }

    const animateBeforeAssign = async (assignment: string) => {
        if (!sectionValidation(stepData, 'person').success) return

        console.log('stepData', stepData)

        const nameData = {
            name: {
                Title: stepData.title,
                Forename: stepData.firstname,
                MiddleName: '',
                Surname: stepData.lastname,
            },
            options: {
                UseRepeatedNameStatus: true,
                AllowInitials: false,
            },
        }
        const data8Valid = await Data8Service('SalaciousName', 'IsUnusableName', nameData)
        console.log(data8Valid)
        if (data8Valid.Result !== '') {
            Report.failure('Salacious Name', 'We are unable to process your request. Please contact support.', 'OK')
            return
        }

        setTimeout(() => {
            assignGroup(assignment)
        }, 400)
    }

    const getCurrentStep = () => {
        return isActive == 'person' ? (
            <FieldGroup title="Contact Information">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <Field fieldLabel="Title" labelPosition="left">
                        <InputFieldSelect fieldName="title" saveValue={preSaveValueFunct} options={titleData}></InputFieldSelect>
                    </Field>
                    <Field fieldLabel="Firstname" labelPosition="left">
                        <InputFieldText fieldName="firstname" saveValue={preSaveValueFunct} />
                    </Field>
                    <Field fieldLabel="Lastname" labelPosition="left">
                        <InputFieldText fieldName="lastname" saveValue={preSaveValueFunct} />
                    </Field>
                    <Field fieldLabel="Date of Birth" labelPosition="left">
                        <InputFieldDate fieldName="dob" saveValue={preSaveValueFunct} />
                    </Field>
                    <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign('contact')} />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
