import styles from './styles.module.scss'
import pageStyles from '../../../globals/pages.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import { CtaButton, Wrapper } from '../../../../reusable'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../../../../assets/logo.png'
import profile from '../../../../../assets/profile.png'
import { IsMobile } from '../../../isMobile/isMobile'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export function OriginalHeader() {
    const [clientName, setClientName] = useState<String>('')
    const [displayMenu, setDisplayMenu] = useState<Boolean>(false)
    const { token } = useParams()
    const navigate = useNavigate()

    const scrollToFaqs = () => {
        setDisplayMenu(false)
        let faqs = document.getElementsByClassName('faq-elem')
        if (faqs.length > 0) {
            faqs[0].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        } else {
            console.log('could not find the FAQS')
            navigate('/#faqs')
            const faqs = document.getElementsByClassName('faq-elem')
            if (faqs.length > 0) {
                faqs[0].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
            }
        }
    }

    const toggleMenu = () => {
        console.log('TOGGLE....', displayMenu)
        setDisplayMenu(!displayMenu)
    }
    return (
        <header className={`${styles.header}  ${responsiveStyles.header} `}>
            <Wrapper>
                <div className={`${styles.innerHeader} ${responsiveStyles.innerHeader} `}>
                    {IsMobile() ? (
                        <>
                            <div className={`${styles.menuButton} ${responsiveStyles.menuButton} `} onClick={() => toggleMenu()}>
                                <span></span>
                            </div>
                            <div className={`${displayMenu ? responsiveStyles.displayMenu : ''} ${styles.menuContainer} ${responsiveStyles.menuContainer} `}>
                                <div className={`${styles.menu} ${responsiveStyles.menu} `}>
                                    <nav>
                                        <ul>
                                            <li>
                                                <Link
                                                    onClick={() => {
                                                        setDisplayMenu(false)
                                                    }}
                                                    to={'/claim/start'}
                                                >
                                                    Check Now
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={() => {
                                                        setDisplayMenu(false)
                                                    }}
                                                    to={'/'}
                                                >
                                                    Home
                                                </Link>
                                            </li>
                                            <li>
                                                <a onClick={() => scrollToFaqs()}>FAQs</a>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={() => {
                                                        setDisplayMenu(false)
                                                    }}
                                                    to={'/contact'}
                                                >
                                                    Contact
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <nav>
                                <ul>
                                    <li>
                                        <Link to={'/'}>Home</Link>
                                    </li>
                                    <li>
                                        <a onClick={() => scrollToFaqs()}>FAQs</a>
                                    </li>
                                </ul>
                            </nav>
                            <img />
                            <nav>
                                <ul>
                                    <li>
                                        <Link to={'/contact'}>Contact</Link>
                                    </li>
                                    <li>
                                        <CtaButton text="Check Now" />
                                    </li>
                                </ul>
                            </nav>
                        </>
                    )}
                </div>
            </Wrapper>
        </header>
    )
}
