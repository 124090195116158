import React, { useEffect, useState } from 'react'
import globals from '../../../globals/pages.module.scss'
import { Report } from 'notiflix/build/notiflix-report-aio'

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import { titleData } from '../../../data/titles'
import { contactPreferencesData } from '../../../data/contactPreferences'
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard, SelectButton } from '../../reusable'

import { FormPartInterface, SaveValueInterface } from '../../../interfaces'
import { Checkbox } from '../../reusable/checkbox'
import { InputFieldAddressLookup } from '../../reusable/inputFieldAddressLookup'
import { lenderData } from '../../../data/lenders'
import { sectionValidation } from '../../../helpers'
import { dvlaCheckService } from '../../../services/dvla-check'
import { isMobile } from 'react-device-detect'
const mobileLabels = {
    1: '£1,0000',
    25000: '£25,0000',
    50000: '£50,0000',
}
const desktopLabels = {
    1: '£1,0000',
    10000: '£10,0000',
    20000: '£20,0000',
    30000: '£30,0000',
    40000: '£40,0000',
    50000: '£50,0000',
}
export const LenderDetails = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props
    const [currentStep, setCurrentStep] = useState(0)
    const [postCurrentStep, setPostCurrentStep] = useState(0)
    const [sliderValue, setSliderValue] = useState<any>(1000)
    const [disclosed, setDisclosed] = useState<any>('')
    const [stepData, setStepData] = useState<any>({})

    const [labels, setLabels] = useState<any>(isMobile ? mobileLabels : desktopLabels)
    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave)
        let tempStepData = stepData
        tempStepData[dataToSave.field] = dataToSave.value
        setStepData(tempStepData)

        if (dataToSave.field == 'lender') {
            const saved = saveValueFunct({ field: 'disclosedCommission', value: 'yes' })
            setDisclosed('yes')
        }

        if (dataToSave.field == 'disclosedCommission') setDisclosed(dataToSave.value)

        if (dataToSave.field == 'registration' && dataToSave.value.length > 3) {
            const carData = dvlaCheckService(dataToSave.value)
        }
        return saved
    }

    const animateBeforeAssign = (assignment: string) => {
        if (!sectionValidation(stepData, 'lender').success) {
            console.log('ERROR........... VALIDATION.......')
        } else {
            setTimeout(() => {
                assignGroup(assignment)
            }, 400)
        }
    }

    const formatValue = () => {
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(sliderValue)
    }

    useEffect(() => {
        const toSave = {
            field: 'financeAmount',
            value: formatValue(),
        }
        preSaveValueFunct(toSave)
    }, [sliderValue])

    const getCurrentStep = () => {
        return isActive == 'lender' ? (
            <FieldGroup title="Vehicle Finance Information">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    {/* <FieldContainer> */}
                    <Field extraStyle={{ padding: '0' }} fieldLabel="Vehicle Finance Lender" labelPosition="left">
                        <InputFieldSelect fieldName={`lender`} saveValue={preSaveValueFunct} options={lenderData} />
                    </Field>
                    {/* </FieldContainer> */}
                    <Field extraStyle={{ padding: '0' }} fieldLabel="Did the dealership leave the commission they received undisclosed?" labelPosition="left">
                        <FieldContainer>
                            <SelectButton
                                btnType={'primary'}
                                text={'Yes'}
                                onClick={() => preSaveValueFunct({ field: 'disclosedCommission', value: 'yes' })}
                                isSelected={disclosed == 'yes'}
                            />
                            <SelectButton btnType={'primary'} text={'No'} onClick={() => preSaveValueFunct({ field: 'disclosedCommission', value: 'no' })} isSelected={disclosed == 'no'} />
                        </FieldContainer>
                    </Field>
                    <Field extraStyle={{ padding: '0' }} fieldLabel="How much did you borrow?" labelPosition="left">
                        {/* <InputFieldText fieldName="financeAmount" saveValue={preSaveValueFunct} fieldType={"number"} /> */}
                        <div className={globals.sliderContainer}>
                            <p>{formatValue()}</p>
                            <Slider min={1000} max={50000} value={sliderValue} step={1000} tooltip={false} labels={labels} onChange={(value) => setSliderValue(value)} />
                        </div>
                        <br />
                    </Field>

                    <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign('vehicle')} />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        )
    }

    return getCurrentStep()
}
/**
 * 
                                        ? 
                                        : 
 */
